<template>
  <el-dialog
    :title="$t('orderPaymentBlock.dialog.markRefund.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
      <BaseElFormItem :label="$t('markRefundDialog.markPrice.title')" prop="markPrice">
        <BaseElInput v-model="formData.markPrice" placeholder="請輸入金額" />
      </BaseElFormItem>
    </BaseElForm>
    <div class="hint" style="width: 560px">
      {{ $t('markRefundDialog.markPrice.hint.title') }}
    </div>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text') }}</BaseElButton>
      <BaseElButton ref="createBtn" v-loading="loading" type="primary" @click="markRefund">
        {{ $t('common.button.confirm.text') }}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { OrderDepositRefund, OrderCheckoutRefund } from '@/api/reservation'
import { manualRefundPaymentType } from '@/config/deposit'
import { get } from 'lodash'
import { computed, defineComponent, reactive, ref, useEmit } from 'vue'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'MarkRefundDialog',
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    depositData: {
      type: Object,
      default: () => ({}),
    },
    data: { type: Object, default: () => ({}) },
    chargeType: String,
    maxPrice: Number,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const formRef = ref(null)
    const loading = ref(false)
    const formData = reactive({
      markPrice: null,
    })
    const formRules = computed(() => {
      const rules = {
        markPrice: [noEmptyRules(), isDigitRules()],
      }
      if (props.maxPrice) {
        rules.markPrice.push(rangeRules(1, props.maxPrice))
      }
      return rules
    })

    const markRefund = async () => {
      if (!(await formUtils.checkForm(formRef.value))) return

      let apiMethod
      const paidType = get(props.data, 'refundType')
      const comment = get(props.data, 'refundTypeComment')
      const type = get(manualRefundPaymentType[paidType], 'value')

      if (props.chargeType === 'deposit') {
        apiMethod = OrderDepositRefund
      } else if (props.chargeType === 'checkout') {
        apiMethod = OrderCheckoutRefund
      }

      loading.value = true
      const [, err] = await apiMethod({
        shopId: shopId.value,
        id: props.orderData.id,
        refundType: type,
        refundTypeComment: comment || undefined,
        refundPrice: formData.markPrice,
      })
      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(i18n.t('common.message.markRefundSuccess'))
      emit('marked')
      emit('close')
    }

    return {
      loading,
      formData,
      formRules,
      markRefund,
    }
  },
})

</script>

<style lang="postcss" scoped>
.hint {
  @apply text-danger break-all whitespace-normal text-sm w-auto leading-[17.38px] mt-[12px];
}
</style>
